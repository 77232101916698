import React from 'react';
import { createTheme } from '@lssa/slate-core'
import { ThemeProvider } from '@mui/material/styles'
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const theme = createTheme(['commercial-platform','non-production'])
const App = () => {

  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
  )
}

export default App;
